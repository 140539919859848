import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from '@emotion/styled';
import { ContainerColumn } from '@components/styled';
import { PortfolioCard } from '@entities/portfolio';
import PortfolioTestEditor from './PortfolioTestEditor';
const Container = styled(ContainerColumn)(() => ({
    height: 'auto',
    alignItems: 'center',
    gap: 24,
}));
const PortfolioPlayground = () => {
    const [originalPortfolio] = useState({
        id: '1',
        profileId: '1',
        logo: '/avatars/Avatar-4.png',
        portfolioName: 'Binance Lounge 🥂',
        subscriptionPrice: 135,
        subscriptionPriceCurrency: 'usd',
        type: 'SPOT',
        referral_asset: '',
        visibility: 'public',
        past_week_difference: 11.7,
        past_month_difference: 83.75,
        past_month_start_date: '01/04/2021',
        past_month_end_date: '12/03/2021',
        past_ninety_days_difference: 161.2,
        overall_difference: 153.89,
        whole_period_days_count: 89,
        viewed: 1333,
        chat_activity: 93,
        favorited_count: 23,
        shared_count: 11,
        min_balance: 800,
        min_balance_currency: 'usd',
        traders_balance: 1675.5,
        traders_balance_currency: 'usd',
        followers: {
            count: 999,
            average_followers_monthly_return: 70.29,
            average_followers_monthly_return_first: 84.9,
            average_followers_monthly_return_last: 64.5,
            preview_followers: [
                {
                    id: 1,
                    username: 'Joe So',
                    avatar: '/avatars/Avatar-1.png',
                },
                {
                    id: 2,
                    username: 'Joe So',
                    avatar: '',
                },
                {
                    id: 3,
                    username: 'Joe So',
                    avatar: '/avatars/Avatar.png',
                },
                {
                    id: 4,
                    username: 'Joe So',
                    avatar: '/avatars/Avatar-3.png',
                },
            ],
        },
        maxDrawdown: {
            daily: -2.23,
            monthly: -12.23,
            weekly: -23.13,
            '90days': 7.67,
        },
        copiers_count: 1,
        user_subscription_summary: undefined,
        graph_data: [],
        exchange: {
            id: '1',
            userId: '1',
            type: 'BINANCE',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'First_Binance Spot',
            },
            wallets: [],
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        profile: undefined,
        minimum_balance_type: '1_to_1',
        is_archived: false,
        has_error: false,
    });
    const [editablePortfolio, setEditablePortfolio] = useState(originalPortfolio);
    const onPortfolioSave = (portfolioData) => {
        try {
            setEditablePortfolio(JSON.parse(portfolioData));
        }
        catch (ex) {
            console.warn(ex);
            alert('Ошибка считывания портфолио! Проверьте целостность JSON или нажмите Reset чтобы внести изменения еще раз.');
        }
    };
    const onPortfolioReset = () => {
        setEditablePortfolio(originalPortfolio);
    };
    return (_jsxs(Container, { children: [_jsx(PortfolioTestEditor, { portfolio: editablePortfolio, onSave: onPortfolioSave, onReset: onPortfolioReset }), _jsxs(Container, { children: [_jsx(PortfolioCard, { portfolio: editablePortfolio, viewType: 'card' }), _jsx(PortfolioCard, { portfolio: editablePortfolio, viewType: 'list' })] })] }));
};
export default PortfolioPlayground;
