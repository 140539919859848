import { jsx as _jsx } from "react/jsx-runtime";
import { Trans } from 'react-i18next';
const OverviewTabTitle = () => Trans({ ns: 'common', i18nKey: 'pages.overview.overview' });
const PlatformBalanceTabTitle = ({ profileType = undefined, }) => Trans({
    ns: 'common',
    i18nKey: profileType === 'TRADER' ?
        'pages.overview.trader_balance' : 'pages.overview.client_balance',
});
const MyProfileTabTitle = () => Trans({ ns: 'common', i18nKey: 'pages.my_profile' });
const getHomeTabs = (profileType) => [
    {
        label: _jsx(OverviewTabTitle, {}),
        value: 'overview',
    },
    {
        label: _jsx(PlatformBalanceTabTitle, { profileType: profileType }),
        value: 'platform_balance',
    },
    {
        label: _jsx(MyProfileTabTitle, {}),
        value: 'my_profile',
    },
];
export default getHomeTabs;
