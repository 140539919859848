import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import RenderText from '@shared/ui/display/table-renderers/RenderText';
import RenderProfileThumbnail from '@shared/ui/display/table-renderers/RenderProfileThumbnail';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import { tableRendererWrapper } from '@shared/ui/display/Table';
// import { useGetProfilesQuery } from '../../../../../store/slices/profile/api'
import { SurfaceColumn } from '@components/styled';
const LoaderContainerMinHeight = '486px';
const TableContainer = styled(SurfaceColumn)(props => ({
    width: 872,
    height: 'auto',
    padding: props.theme.spacing_sizes.l,
    flexShrink: 0,
    alignItems: 'center',
}));
const getColumnsDefinition = () => ([
    {
        header: 'profile',
        accessorFn: model => model,
        enableSorting: false,
        cell: tableRendererWrapper(RenderProfileThumbnail),
    },
    {
        header: 'nickname',
        accessorKey: 'nickname',
        enableSorting: false,
        cell: tableRendererWrapper(RenderText),
    },
    {
        header: 'publicName',
        accessorKey: 'publicName',
        enableSorting: false,
        cell: tableRendererWrapper(RenderText),
    },
    {
        header: 'type',
        accessorFn: model => (model.type === 'CLIENT' ? 'client' : 'trader'),
        enableSorting: false,
        cell: tableRendererWrapper(RenderText),
    },
]);
const UserProfilesTable = ({ className = undefined, }) => {
    const columnDef = getColumnsDefinition();
    // const { data, isFetching, isError } = useGetProfilesQuery()
    return (_jsx(TableContainer, { className: className, children: _jsx(LoadingIndicator, { isLoading: false, 
            // isLoading={isFetching}
            takeFullContainerSpace: true, minHeight: LoaderContainerMinHeight }) }));
};
export default UserProfilesTable;
